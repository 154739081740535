<template>
  <div style="width: 100%; height: 100%" class="comp-echart">
    <div class="loading-tips" v-if="isLoading"></div>
    <div class="empty-tips" v-else-if="isEmpty"></div>
    <v-chart v-else :autoresize="true" :options="options" style="width: 100%" />
  </div>
</template>
 
<script>
import Bus from "@/utils/event-bus.js";
export default {
  data() {
    return {
      compData: undefined,
    };
  },
  mounted() {
    this.loadData();
    Bus.$on("reload-data", this.loadData);
    Bus.$on("province-change", this.loadData);
  },
  methods: {
    loadData() {
      this.$get("screen/datav/year-orders", {
        province: this.$store.state.common.provinceName,
      }).then((r) => {
        this.compData = r.data.data || {};
      });
    },
  },
  computed: {
    isLoading() {
      return typeof this.compData == "undefined";
    },
    isEmpty() {
      if (!this.compData) {
        return true;
      }
      let count = 0;
      for (let name in this.compData) {
        count += this.compData[name]
      }
      return count == 0;
    },
    options() {
      let xDatas = Object.keys(this.compData).map((item) => item + "年");
      let yDatas = Object.values(this.compData);

      return {
        tooltip: {
          trigger: "axis",
          formatter: "年份： {b}<br/>订单数： {c}",
          axisPointer: {
            type: "shadow",
          },
        },
        grid: {
          left: "0px",
          right: "10px",
          top: "30px",
          bottom: "-5px",
          containLabel: true,
        },
        xAxis: {
          type: "category",
          splitLine: { show: true, lineStyle: { color: "#20bcfc99" } },
          axisLine: { lineStyle: { color: "#20bcfc99" } },
          axisLabel: { rotate: 10, color: "#555" },
          data: xDatas,
        },
        yAxis: {
          type: "value",
          name: "订单数",
          scale: true,
          minInterval: 5,
          splitLine: { show: true, lineStyle: { color: "#20bcfc99" } },
          axisLine: { lineStyle: { color: "#20bcfc99" } },
          nameTextStyle: { color: "#abb8ce" },
          axisLabel: { color: "#555" },
          axisTick: { show: true },
        },
        series: [
          {
            data: yDatas,
            type: "line",
            itemStyle: {
              normal: { color: "#555", lineStyle: { color: "#67c5ff" } },
            },
          },
        ],
        legend: {
          textStyle: { color: "#555" },
        },
      };
    },
  },
};
</script>

<style>
.echarts {
  width: 100%;
  height: 100%;
}
</style>