<template>
  <div class="footer">
    <div class="copyright">
      Copyright
      <a-icon type="copyright"/>
      <span v-html="copyright"></span>&nbsp;
      <span>伊顿电力设备有限公司</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'GlobalFooter',
  props: ['copyright']
}
</script>

<style lang="less" scoped>
  .footer {
    margin: 20px 0;
    text-align: center;
    .copyright {
      font-size: 14px;
      i {
        font-size: .8rem !important;
      }
    }

    &a {
      text-decoration: none;
    }
  }
</style>
