import Vue from 'vue'
import Febs from './FEBS'
import router from './router'
import Antd from 'ant-design-vue'
import store from './store'
import request from 'utils/request'
import db from 'utils/localstorage'
import AesEncrypt from 'utils/aesEncrypt'
import VueMeta from 'vue-meta'
import 'utils/reset.less'
import 'ant-design-vue/dist/antd.less'
import echarts from 'echarts'
import VueECharts from 'vue-echarts' 
import dataV from '@jiaminghi/data-view'
import 'utils/install'
import moment from 'moment'
import VDistpicker from 'v-distpicker'
import MyIcon from '@/components/custom-icons/my-icon.vue'

Vue.config.productionTip = false
Vue.config.devtools = true;
Vue.use(dataV)
Vue.use(Antd)
Vue.component('my-icon', MyIcon)
Vue.use(db)
Vue.use(VueMeta)
Vue.prototype.$echarts = echarts
Vue.component('v-chart', VueECharts)
Vue.use({
  install (Vue) {
    Vue.prototype.$db = db
  }
})

Vue.prototype.$post = request.post
Vue.prototype.$get = request.get
Vue.prototype.$put = request.put
Vue.prototype.$delete = request.delete
Vue.prototype.$export = request.export
Vue.prototype.$download = request.download
Vue.prototype.$upload = request.upload
Vue.prototype.$originalGet = request.originalGet
Vue.prototype.$aesEncrypt = AesEncrypt
Vue.component('v-distpicker', VDistpicker)

Vue.mixin({
  methods: {
    datetime(d) {
      if (!d) return null
      return moment(d).format('YYYY/MM/DD HH:mm')
    },
    date(d) {
      if (!d) return null
      return moment(d).format('MM/DD')
    },
    jsonformat(d) {
      if (typeof d == 'object') {
        return JSON.stringify(d, null, 2)
      }
      return JSON.stringify(JSON.parse(d), null, 2)
    }
  }
})

/* eslint-disable no-new */
new Vue({
  router,
  store,
  render: h => h(Febs)
}).$mount('#febs')
