<template>
  <div class="login-root">
    <video autoplay loop muted class="back-video" v-if="true">
      <source src="static/file/video.mp4" type="video/mp4" />
    </video>
    <div class="login-head">
      <img src="eaton-online-logo.png" alt="伊顿员工在线" />
    </div>
    <div class="login-view">
      <div class="page-body">
        <div class="page-info">
          <div>伊顿员工在线</div>
          <div>后台管理系统</div>
        </div>
        <div class="login-section">
          <div class="logo_box">
            <h3><img src="eaton-icon.png" /></h3>
            <div class="input_outer">
              <img class="us_uer" src="./user.svg" />
              <input
                ref="name"
                v-model="formData.name"
                class="text"
                type="text"
                placeholder="请输入员工号、用户名或手机号"
              />
            </div>
            <div class="input_outer">
              <img class="us_uer" src="./lock.svg" />
              <input
                ref="password"
                v-model="formData.password"
                class="text"
                type="password"
                placeholder="请输入密码"
              />
            </div>
            <div class="submit_button">
              <a class="act-but submit" @click="submit"
                ><a-icon
                  v-if="loading"
                  type="loading"
                  style="margin-right: 20px"
                /><span v-else>登录</span></a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="login-foot">
      <div class="info">
        <!-- Copyright © 2018-2020 INDUSFORCE. All rights reserved.
        国佳云为（江苏）信息科技有限公司 版权所有 -->

        Copyright © 2023-2025 钱多多财务咨询（常州）有限公司 版权所有
        <br />
        网站备案号<a
          class="link"
          target="_blank"
          href="https://beian.miit.gov.cn/"
          >苏ICP备2023003038号</a
        >
        <!-- <br />
        <img src="static/img/gn.png" alt=""><a
          class="link"
          target="_blank"
          href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=32040202000541"
          >苏公网安备 32040202000541号</a
        > -->
        <br />
        <span>伊顿电力设备有限公司</span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from "vuex";

export default {
  name: "Login",
  data() {
    return {
      formData: {
        name: "",
        password: "",
      },
      loading: false,
    };
  },
  created() {
    this.$db.clear();
    this.$router.options.routes = [];
  },
  methods: {
    submit() {
      let name = this.formData.name;
      let password = this.formData.password;

      if (name && name.length < 2 && name.length > 50) {
        this.$message.warning("长度在 2 到 50 个字符");
        this.$refs.name.focus();
        return;
      }
      if (password && password.length < 6 && password.length > 15) {
        this.$message.warning("长度在 6 到 15 个字符");
        this.$refs.name.message();
        return;
      }
      this.loading = true;
      this.$post("login", {
        terminal: "Web",
        name: name,
        password: this.$aesEncrypt.encrypt(password),
      })
        .then((r) => {
          let data = r.data.data;
          this.saveLoginData(data);
          setTimeout(() => {
            this.$router.push("/");
            this.loading = false;
          }, 500);
          this.getDicts();
        })
        .catch((e) => {
          console.error(e);
          setTimeout(() => {
            this.loading = false;
          }, 500);
        });
    },
    ...mapMutations({
      setToken: "account/setToken",
      setExpireTime: "account/setExpireTime",
      setPermissions: "account/setPermissions",
      setRoles: "account/setRoles",
      setUser: "account/setUser",
      setTheme: "setting/setTheme",
      setLayout: "setting/setLayout",
      setMultipage: "setting/setMultipage",
      fixSiderbar: "setting/fixSiderbar",
      fixHeader: "setting/fixHeader",
      setColor: "setting/setColor",
      setDicts: "dict/setDicts",
    }),
    saveLoginData(data) {
      this.setToken(data.token);
      this.setExpireTime(data.exipreTime);
      this.setUser(data.user);
      this.setPermissions(data.permissions);
      this.setRoles(data.roles);
      if(data.config!=undefined) {
        this.setTheme(data.config.theme);
        this.setLayout(data.config.layout);
        this.setMultipage(data.config.multiPage === "1");
        this.fixSiderbar(data.config.fixSiderbar === "1");
        this.fixHeader(data.config.fixHeader === "1");
        this.setColor(data.config.color);
      }
    },
    getDicts() {
      this.$get("dict/trim")
        .then((r) => {
          let data = r.data;
          this.saveDictData(data);
        })
        .catch((e) => {
          console.error(e);
        });
    },
    saveDictData(data) {
      this.setDicts(data);
    },
  },
};
</script>

<style lang="less" scoped>
.login-root {
  height: 100vh;
  .back-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: fill;
    z-index: -1;
  }
  .login-head {
    height: 100px;
    background-color: #fffe;
    img {
      width: 270px;
      margin-left: 20%;
      margin-top: 10px;
    }
  }
  .login-foot {
    height: 200px;
    background-color: #fffe;
    .info {
      margin-left: 20%;
      font-size: 14px;
      color: gray;
      padding-top: 50px;
      line-height: 35px;
      text-align: left;
    }
    .link {
      color: #2b8aff;
      text-decoration: none;
      margin-left: 10px;
      font-weight: 400;
    }
  }
  .login-view {
    height: calc(100vh - 300px);
    min-height: 400px;
    min-width: 1200px;
    // background: #096dd9;
    // background-image: url(/static/file/login-bg.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top center;

    .page-body {
      display: flex;
      align-items: center;
      justify-content: space-between;
      // @media only screen and (min-width: 1200px) {
      // }
      width: 60%;
      height: 100%;
      margin-left: 20%;
    }
    .page-info {
      height: 335px;
      z-index: 0;
      color: white;
      font-size: 45px;
    }
    .login-section {
      height: 335px;
      z-index: 0;
      object-fit: fill;
      background: #fff1;
      padding: 30px 70px 40px 70px;
      border: 1px solid #ffffff22;
      .logo_box {
        h3 {
          color: white;
          font-size: 25px;
          letter-spacing: 5px;
          font-weight: bold;
          margin-bottom: 30px;
          img {
            width: 170px;
          }
        }
        .input_outer {
          margin: 20px 0;
          position: relative;
          .us_uer {
            position: absolute;
            left: 5px;
            top: 5px;
            width: 30px;
            height: 30px;
            z-index: 1000;
          }
          .text {
            width: 300px;
            height: 40px;
            padding-left: 50px;
            letter-spacing: 2px;
            caret-color: orange;
            color: white;
            outline: none;
            display: inline-block;
            font: 14px "microsoft yahei", Helvetica, Tahoma, Arial,
              "Microsoft jhengHei";
            border: none;
            border-bottom: 1px solid #fff;
            background: none;
            line-height: 30px;
          }
        }
        .submit_button {
          .submit {
            margin-top: 40px;
            line-height: 40px;
            display: block;
            color: white;
            cursor: pointer;
          }
          .act-but {
            border-radius: 2px;
            text-decoration: none;
            outline: none;
            text-align: center;
            font-size: 20px;
            background: #0096e6;
            font-weight: lighter;
            &:hover {
              background: #0088dd;
            }
          }
        }
      }
    }
  }
}
</style>
