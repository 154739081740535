import { message } from 'ant-design-vue/es'

let lessNodesAppended
const updateTheme = colors => {
  if (!colors) {
    return
  }
  // const hideMessage = message.loading('加载主题...', 0)
  function buildIt () {
    if (!window.less) {
      return
    }
    setTimeout(() => {
      window.less
        .modifyVars(colors)
        .then(() => {
          // hideMessage()
        })
        .catch((e) => {
          console.log(e)
          message.error('Failed to update theme')
          // hideMessage()
        })
    }, 200)
  }
  if (!lessNodesAppended) {
    // insert less.js and color.less
    const lessStyleNode = document.createElement('link')
    const lessConfigNode = document.createElement('script')
    const lessScriptNode = document.createElement('script')
    let ctx = window.document.location.pathname === '/' ? '' : window.document.location.pathname
    lessStyleNode.setAttribute('rel', 'stylesheet/less')
    lessStyleNode.setAttribute('href', ctx + '/static/less/Color.less')
    lessConfigNode.innerHTML = `
      window.less = {
        async: true,
        env: 'production',
        javascriptEnabled: true
      }
    `
    // https://cdn.bootcss.com/less.js/3.9.0/less.min.js
    lessScriptNode.src = ctx + '/static/less/less.min.js'
    lessScriptNode.async = true
    lessScriptNode.onload = () => {
      buildIt()
      lessScriptNode.onload = null
    }
    document.body.appendChild(lessStyleNode)
    document.body.appendChild(lessConfigNode)
    document.body.appendChild(lessScriptNode)
    lessNodesAppended = true
  } else {
    buildIt()
  }
}
export { updateTheme }
