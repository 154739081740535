<template>
  <div class="comp-2">
    <dv-digital-flop
      :config="digitalConfig3"
      style="width: 100%; height: 4rem"
    />
    <dv-digital-flop
      :config="digitalConfig4"
      style="width: 100%; height: 4rem"
    />
  </div>
</template>

<script>
import Bus from "@/utils/event-bus.js";

let configData = [
  [2560, 30],
  [2048, 30],
  [1920, 25],
  [1856, 25],
  [1856, 20],
  [1792, 20],
  [1680, 20],
  [1600, 17],
  [1440, 17],
  [1400, 17],
  [1366, 17],
  [1280, 17],
];

export default {
  data() {
    return {
      compData: {
        finishedTaskCountThisYear: 0,
        reachRate: 0,
      },
      digitalSize: 20,
    };
  },
  mounted() {
    this.loadData();
    window.addEventListener("resize", this.onResize);
    this.onResize();

    Bus.$on("reload-data", this.loadData);
    Bus.$on("province-change", this.loadData);
  },
  methods: {
    loadData() {
      this.$get("/screen/datav/general-situation", {
        province: this.$store.state.common.provinceName,
      }).then((r) => {
        let data = r.data.data;

        let allTaskCountThisYear = data.allTaskCountThisYear;
        this.compData.finishedTaskCountThisYear =
          data.finishedTaskCountThisYear;
        this.compData.reachRate =
          Math.floor(
            (data.finishedTaskCountThisYear / allTaskCountThisYear) * 100
          ) || 0;
      });
    },
    onResize() {
      let w = window.innerWidth;
      for (let index = 0; index < configData.length; index++) {
        let current = configData[index];
        if (w >= current[0]) {
          this.digitalSize = current[1];
          break;
        }
      }
    },
  },
  computed: {
    digitalConfig3() {
      return {
        number: [this.compData.finishedTaskCountThisYear],
        textAlign: "center",
        content: "已完成数量 {nt}",
        animationFrame: 100,
        style: {
          fontSize: this.digitalSize,
          fill: "#1f427c",
        },
      };
    },
    digitalConfig4() {
      return {
        number: [this.compData.reachRate],
        textAlign: "center",
        content: "服务器达成率 {nt}%",
        animationFrame: 100,
        style: {
          fontSize: this.digitalSize,
          fill: "#1f427c",
        },
      };
    },
  },
};
</script>

<style lang="less" scoped>
.comp-2 {
}
</style>