<template>
  <div class="comp-4" style="height: 850px">
    <div class="loading-tips" v-if="isLoading"></div>
    <div class="empty-tips" v-else-if="isEmpty"></div>
    <template v-else>
      <dv-capsule-chart
        :config="config"
        style="width: 100%; height: calc(100% - 20px); margin-top: 20px"
      />
      <div class="bottom">
        <span style="color: #3db6fc; font-size: 15px"
          >第 {{ pageIndex + 1 }} / {{ pageSize }} 页</span
        >
      </div>
    </template>
  </div>
</template>

<script>
import Bus from "@/utils/event-bus.js";
export default {
  data() {
    return {
      compData: undefined,
      pageIndex: 0,
    };
  },
  mounted() {
    this.loadData();
    Bus.$on("reload-data", this.loadData);
    Bus.$on("province-change", this.loadData);
    setInterval(() => {
      this.pageIndex = (this.pageIndex + 1) % this.pageSize;
    }, 8000);
  },
  methods: {
    loadData() {
      this.$get("/screen/datav/area-orders", {
        province: this.$store.state.common.provinceName,
      }).then((r) => {
        let data = r.data.data || {};
        this.pageIndex = 0;
        this.compData = Object.keys(data).map((k) => {
          return {
            name: k,
            value: data[k],
          };
        }).sort((a, b) => b.value - a.value);
      });
    },
  },
  computed: {
    isLoading() {
      return typeof this.compData == "undefined";
    },
    isEmpty() {
      return !this.compData || this.compData.length == 0;
    },
    pageCount() {
      let x = this.countAll;
      let map = {
        20: (x / 20) % 1,
      };
      let min, key;
      for (let k in map) {
        if (typeof min == "undefined" || min > map[k]) {
          min = map[k];
          key = k;
        }
      }
      console.log(key, this.countAll);
      return key;
    },
    countAll() {
      if (this.compData) {
        return this.compData.length;
      }
      return 0;
    },
    pageSize() {
      return Math.ceil(this.countAll / this.pageCount);
    },
    config() {
      let list = [];
      if (this.compData) {
        list = this.compData.slice(
          this.pageIndex * this.pageCount,
          (this.pageIndex + 1) * this.pageCount
        );
      }

      return {
        data: list,
        unit: "次",
        showValue: true,
      };
    },
  },
};
</script>

<style lang="less">
.comp-4 {
  position: relative;
  .dv-capsule-chart {
    .capsule-item {
      position: relative;
      .capsule-item-column {
        position: static;
        .capsule-item-value {
          position: absolute;
          right: -5px;
          color: #3db6fc;
          &::after {
            content: "次";
            display: inline-block;
            width: 10px;
            height: 10px;
          }
        }
      }
    }
    .label-column div {
      color: #555;
    }
    .unit-label {
      visibility: hidden;
    }
    .unit-text {
      visibility: hidden;
    }
  }
  .bottom {
    position: absolute;
    bottom: 30px;
    width: 100%;
    display: flex;
    justify-content: center;
  }
}
.comp-4/deep/ {
  .label-column {
    display: block!important;
    div {
      margin-bottom: 20px!important;
    }
  }
  .capsule-container {
    display: block!important;
    .capsule-item {
      margin: 5px 0px 30px!important;
      display: flex!important;
    }
  }
}
</style>
