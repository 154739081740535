<template>
  <div
    :class="[
      multipage === true ? 'multi-page' : 'single-page',
      'not-menu-page',
      'home-page',
    ]"
  >
    <a-row :gutter="8" class="head-info">
      <a-card class="head-info-card">
        <a-col :span="12">
          <div class="head-info-avatar">
            <img alt="头像" :src="avatar" />
          </div>
          <div class="head-info-count">
            <div class="head-info-welcome">
              {{ welcomeMessage }}
            </div>
            <div class="head-info-desc">
              <p>
                {{ user.deptName ? user.deptName : "暂无部门" }} |
                {{ user.roleName ? user.roleName : "暂无角色" }}
              </p>
            </div>
            <div class="head-info-time">
              上次登录时间：{{
                user.lastLoginTime ? user.lastLoginTime : "第一次访问系统"
              }}
            </div>
          </div>
        </a-col>
        <a-col :span="12">
          <div>
            <a-col :span="4">
              <head-info
                title="今日访问"
                :content="todayVisitCount"
                :center="false"
                :bordered="false"
              />
            </a-col>
            <a-col :span="4">
              <head-info
                title="总访问量"
                :content="totalVisitCount"
                :center="false"
              />
            </a-col>
            <a-row class="more-info">
              <a-col :span="4">
                <head-info
                  title="已完成数量"
                  :content="this.compData.finishedTaskCountThisYear"
                  :center="false"
                  :bordered="false"
                />
              </a-col>
              <a-col :span="4">
                <head-info
                  title="服务器达成率"
                  :content="this.compData.reachRate + '%'"
                  :center="false"
                  :bordered="false"
                />
              </a-col>
              <a-col :span="4">
                <head-info
                  title="年服务总量"
                  :content="this.compData.allTaskCountThisYear"
                  :center="false"
                  :bordered="false"
                />
              </a-col>
              <a-col :span="4">
                <head-info
                  title="服务出勤率"
                  :content="this.compData.attendanceRate + '%'"
                  :center="false"
                  :bordered="false"
                />
              </a-col>
            </a-row>
          </div>
        </a-col>
      </a-card>
    </a-row>
    <a-row :gutter="8" class="section-margin-top">
      <a-col :span="12">
        <a-card title="工单类型统计">
          <Comp4 />
        </a-card>
        <a-card title="服务人员列表" class="section-margin-top">

            <a-select
            slot="extra"
            :default-value="0"
    style="width: 180px"
    @change="handleChange"
  >
    <a-select-option v-for="d in deptGroup" :key="d.deptTypeId" :value="d.deptTypeId">{{d.deptTypeName}}</a-select-option>
  </a-select>

    <!-- <a-select slot="extra" default-value="0" style="width: 120px" @change="handleChange">
      <a-select-option value="0">
        全部
      </a-select-option>
      <a-select-option value="9">
        伊顿组织
      </a-select-option>
      <a-select-option value="68">
        顾问A
      </a-select-option>
    </a-select> -->
          <Comp5 :compData="this.currentUser" />
        </a-card>
      </a-col>
      <a-col :span="12">
        <a-card title="地区派工统计" style="height: 945px">
          <Comp3 />
        </a-card>
      </a-col>
    </a-row>
  </div>
</template>
<script>
import HeadInfo from "@/views/common/HeadInfo";
import Comp1 from "./home/comp-1";
import Comp2 from "./home/comp-2";
import Comp3 from "./home/comp-3";
import Comp4 from "./home/comp-4";
import Comp5 from "./home/comp-5";
import Comp6 from "./home/comp-6";
import Comp7 from "./home/comp-7";
import { mapState } from "vuex";
import moment from "moment";
import {uniqueByKey} from "@/utils/common.js"

moment.locale("zh-cn");
export default {
  name: "HomePage",
  components: { HeadInfo, Comp1, Comp2, Comp3, Comp4, Comp5, Comp6, Comp7 },
  data() {
    return {
      todayIp: "",
      todayVisitCount: "",
      totalVisitCount: "",
      lastLoginTime: "",
      welcomeMessage: "",
      currentUser: [],
      deptGroup: [{deptTypeId: 0, deptTypeName: '全部'}, {deptTypeId: 9, deptTypeName: '伊顿电力设备有限公司'}],
      currentUserOriginal: [],
      compData: {
        allTaskCountThisYear: 0,
        attendanceRate: 0,
        finishedTaskCountThisYear: 0,
        reachRate: 0,
      },
    };
  },
  computed: {
    ...mapState({
      multipage: (state) => state.setting.multipage,
      user: (state) => state.account.user,
    }),
    avatar() {
      return `static/avatar/${this.user.avatar}`;
    },
  },
  methods: {
    welcome() {
      const date = new Date();
      const hour = date.getHours();
      let time =
        hour < 6
          ? "早上好"
          : hour <= 11
          ? "上午好"
          : hour <= 13
          ? "中午好"
          : hour <= 18
          ? "下午好"
          : "晚上好";
      return `${time}，${this.user.username}`;
    },
    runningProject() {
      this.$get(`index/${this.user.username}`).then((r) => {
        let data = r.data.data;
        this.todayIp = data.todayIp;
        this.todayVisitCount = data.todayVisitCount;
        this.totalVisitCount = data.totalVisitCount;
      });

      this.$get("/screen/datav/general-situation", {
        province: this.$store.state.common.provinceName,
      }).then((r) => {
        let data = r.data.data;
        this.compData.allTaskCountThisYear = data.allTaskCountThisYear;
        this.compData.attendanceRate = Math.floor(data.attendanceRate * 100);

        let allTaskCountThisYear = data.allTaskCountThisYear;
        this.compData.finishedTaskCountThisYear =
          data.finishedTaskCountThisYear;
        this.compData.reachRate =
          Math.floor(
            (data.finishedTaskCountThisYear / allTaskCountThisYear) * 100
          ) || 0;
      });
    },
    loadCurrentUser() {
      this.$get("/screen/datav/current-users").then((r) => {
        this.currentUser = this.currentUserOriginal = r.data.data || [];
        const a = this.currentUserOriginal.filter(
          (item) => item.deptTypeId != 9
        );
        const b = uniqueByKey(a,'deptTypeId')
        b.forEach((item,index,arr)=>{
          this.deptGroup.push({deptTypeId: item.deptTypeId, deptTypeName: item.deptName})
        })
      });
    },
    handleChange(val) {
      this.currentUser = this.currentUserOriginal
      if (val != 0) {
        this.currentUser = this.currentUserOriginal.filter(
          (item) => item.deptTypeId == val
        );
      }
    }
  },
  mounted() {
    this.welcomeMessage = this.welcome();
    this.runningProject();
    this.loadCurrentUser();
  },
};
</script>
<style lang="less">
.home-page {
  margin-bottom: 3rem;
  .section-margin-top {
    margin-top: 1rem;
  }
  .head-info {
    margin-bottom: 0.5rem;
    .head-info-card {
      padding: 0.5rem;
      border-color: #f1f1f1;
      .head-info-avatar {
        display: inline-block;
        float: left;
        margin-right: 1rem;
        img {
          width: 5rem;
          border-radius: 2px;
        }
      }
      .head-info-count {
        display: inline-block;
        float: left;
        .head-info-welcome {
          font-size: 1.05rem;
          margin-bottom: 0.1rem;
        }
        .head-info-desc {
          color: rgba(0, 0, 0, 0.45);
          font-size: 0.8rem;
          padding: 0.2rem 0;
          p {
            margin-bottom: 0;
          }
        }
        .head-info-time {
          color: rgba(0, 0, 0, 0.45);
          font-size: 0.8rem;
          padding: 0.2rem 0;
        }
      }
    }
  }
}
</style>
