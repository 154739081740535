<template>
  <div class="loading-tips" v-if="isLoading"></div>
  <div class="empty-tips" v-else-if="isEmpty"></div>
  <div v-else class="content-box">
    <div class="left-content">
      <dv-scroll-board
        v-if="compData"
        :config="scrollConfig"
        style="width: 100%"
      />
    </div>
    <span class="center-line when-small-hide"></span>
    <div class="right-content when-small-hide">
      <div style="height: 50%">
        <div
          style="
            color: #259ff5;
            font-size: 12px;
            margin-bottom: 10px;
            margin-left: 10px;
            text-align: center;
          "
        >
          剩余服务人员 {{ number2 + number4 }} / {{ number0 }}
        </div>
        <dv-water-level-pond
          :config="panelOption"
          style="width: 60%; height: 80%; margin-left: 25%"
        />
      </div>
      <div class="rightbottom" style="height: 50%">
        <div class="num-row">
          <span class="title">在现场</span>
          <dv-digital-flop :config="digitalConfig1" style="height: 20px" />
        </div>
        <div class="num-row">
          <span class="title">在公司</span>
          <dv-digital-flop :config="digitalConfig2" style="height: 20px" />
        </div>
        <div class="num-row">
          <span class="title">休假中</span>
          <dv-digital-flop :config="digitalConfig3" style="height: 20px" />
        </div>
        <div class="num-row">
          <span class="title">未打卡</span>
          <dv-digital-flop :config="digitalConfig4" style="height: 20px" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Bus from "@/utils/event-bus.js";
export default {
  props: {
    compData: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  data() {
    return {
      number0: 0, //所有人数
      number1: 0, //在现场数
      number2: 0, //在公司数
      number3: 0, //休假数
      number4: 0, //未打卡数
    };
  },
  mounted() {
    this.loadData();
    Bus.$on("reload-data", this.loadData);
    Bus.$on("province-change", this.loadData);
  },
  watch: {
    'compData': function (val) {
      if (val.length) {
      this.number0 = this.compData.length;

      this.number1 = this.compData.filter(
        (item) => item.userSign.status == 1
      ).length;
      this.number2 = this.compData.filter(
        (item) => item.userSign.status == 2
      ).length;
      this.number3 = this.compData.filter(
        (item) => item.userSign.status == 3
      ).length;
      this.number4 = this.compData.filter(
        (item) => item.userSign.status == 4
      ).length;
      }
    }
  },
  methods: {
    loadData() {
    },
  },
  computed: {
    isLoading() {
      return typeof this.compData == "undefined";
    },
    isEmpty() {
      return !this.compData || this.number0 == 0;
    },
    panelOption() {
      let value = Math.ceil(
        ((this.number2 + this.number4) * 100) / this.number0
      );
      return {
        data: [value],
        waveNum: 4,
        waveHeight: 5,
        waveOpacity: 0.3,
        shape: "round",
        colors: ["#1eb0fc"],
      };
    },
    digitalConfig1() {
      return {
        number: [this.number1],
        textAlign: "right",
        content: "{nt} 人",
        animationFrame: 100,
        style: {
          fontSize: 15,
          fill: "#fd895b",
        },
      };
    },
    digitalConfig2() {
      return {
        number: [this.number2],
        textAlign: "right",
        content: "{nt} 人",
        animationFrame: 100,
        style: {
          fontSize: 15,
          fill: "#27ac3d",
        },
      };
    },
    digitalConfig3() {
      return {
        number: [this.number3],
        textAlign: "right",
        content: "{nt} 人",
        animationFrame: 100,
        style: {
          fontSize: 15,
          fill: "#fc5659",
        },
      };
    },
    digitalConfig4() {
      return {
        number: [this.number4],
        textAlign: "right",
        content: "{nt} 人",
        animationFrame: 100,
        style: {
          fontSize: 15,
          fill: "gray",
        },
      };
    },
    scrollConfig() {
      let data = this.compData.map((item) => {
        let one = this.userSignStatus.find(
          (s) => s.keyy == item.userSign.status
        );
        return [
          item.username,
          item.mobile || "(工号)" + item.empno,
          item.deptName,
          item.deptPositionName,
          `<span class="person-status"><img title="${one.valuee}" src="./icon/dot-state-${item.userSign.status}.svg"/> <span class="when-small-show">${one.valuee}</span> </span>`,
        ];
      });

      // 1-在现场 2-在公司 3-休假 4-未打卡

      return {
        rowNum: 7,
        oddRowBGC: "#5588cc00",
        evenRowBGC: "#5588cc00",
        columnWidth: [70],
        waitTime: 3000,
        hoverPause: true,
        data: data.sort((a, b) => a[0].localeCompare(b[0])),
      };
    },
    userSignStatus() {
      return this.$store.state.dict.dicts.user_sign_status;
    },
  },
};
</script>

<style lang="less" scoped>
.content-box {
  display: flex;
  height: 300px;
  position: relative;
  .left-content {
    width: 70%;
    @media only screen and (max-width: 1681px) {
      width: 100%;
    }
  }
  .center-line {
    position: absolute;
    left: 70%;
    top: 50%;
    z-index: 10;
    transform: translateY(-50%);
    background: -webkit-linear-gradient(top, #ffffff00, orange, #ffffff00);
    // background: white;
    width: 2px;
    height: 100%;
    z-index: 557;
  }
  .right-content {
    width: 30%;
    color: #555;

    .rightbottom {
      display: flex;
      flex-direction: column;
      justify-content: space-around;

      .num-row {
        display: flex;
        justify-content: center;

        .title {
          white-space: nowrap;
          margin-left: 20px;
          font-size: 15px;
          line-height: 1;
        }
      }
    }
  }
  .when-small-hide {
    @media only screen and (max-width: 1681px) {
      display: none;
    }
  }
}
</style>

<style lang="less">
.content-box {
  .dv-scroll-board {
    color: #555;
    .rows .ceil {
      .person-status {
        display: flex;
        align-items: center;
        img {
          margin: 8px;
        }
      }
    }
  }
}
</style>