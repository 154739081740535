<template>
  <div style="width: 100%; height: 100%" class="comp-echart">
    <div class="loading-tips" v-if="isLoading"></div>
    <div class="empty-tips" v-else-if="isEmpty"></div>
    <v-chart v-else :autoresize="true" :options="options" style="width: 100%" />
  </div>
</template>
 
<script>
import moment from "moment";
import Bus from '@/utils/event-bus.js';
export default {
  data() {
    return {
      compData: undefined,
    };
  },
  mounted() {
    this.loadData();
    Bus.$on("reload-data", this.loadData);
    Bus.$on("province-change", this.loadData);
  },
  methods: {
    loadData() {
      this.$get("screen/datav/month-orders", {
        province: this.$store.state.common.provinceName,
      }).then((r) => {
        this.compData = r.data.data || {};
      });
    },
    convertData() {
      let years = Object.keys(this.compData);

      if (years.length == 0) {
        let year = moment().format("YYYY");
        let map1 = {};
        let map2 = {};

        for (let i = 0; i < 12; i++) {
          map1[i] = 0;
          map2[i] = 0;
        }

        let map = {};
        map[year - 1] = map1;
        map[year] = map2;
        return map;
      }

      if (years.length == 1) {
        let year = years[0];
        let map1 = {};
        let map2 = {};
        for (let i = 0; i < 12; i++) {
          map1[i] = 0;
          map2[i] = this.compData[year][i] || 0;
        }

        let map = {};
        map[year - 1] = map1;
        map[year] = map2;
        return map;
      }

      if (years.length == 2) {
        let map1 = {};
        let map2 = {};
        for (let i = 0; i < 12; i++) {
          map1[i] = this.compData[years[0]][i] || 0;
          map2[i] = this.compData[years[1]][i] || 0;
        }

        let map = {};
        map[years[0]] = map1;
        map[years[1]] = map2;
        return map;
      }
    },
  },
  computed: {
    isLoading() {
      return typeof this.compData == 'undefined'
    },
    isEmpty() {
      return !this.compData || Object.keys(this.compData).length == 0
    },
    options() {
      let map = this.convertData();
      let series = Object.keys(map).map((year) => {
        let obj = map[year];
        return {
          name: year + "年",
          data: Object.values(obj),
          type: "bar",
        };
      });

      return {
        color: ["#3398DB", "#33DB98"],
        tooltip: {
          trigger: "axis",
          // formatter: "月份： {b}<br/>订单数： {c}次{d}次",
          axisPointer: {
            type: "shadow",
          },
        },
        legend: {
          data: Object.keys(map),
        },
        grid: {
          left: "0px",
          right: "10px",
          top: "30px",
          bottom: "-5px",
          containLabel: true,
        },
        xAxis: {
          type: "category",
          splitLine: { show: true, lineStyle: { color: "#20bcfc99" } },
          axisLine: { lineStyle: { color: "#20bcfc99" } },
          axisLabel: { rotate: 10, color: "#555" },
          data: [
            "1月",
            "2月",
            "3月",
            "4月",
            "5月",
            "6月",
            "7月",
            "8月",
            "9月",
            "10月",
            "11月",
            "12月",
          ],
        },
        yAxis: {
          type: "value",
          name: "订单数",
          scale: true,
          minInterval: 5,
          splitLine: { show: true, lineStyle: { color: "#20bcfc99" } },
          axisLine: { lineStyle: { color: "#20bcfc99" } },
          nameTextStyle: { color: "#abb8ce" },
          axisLabel: { color: "#555" },
          axisTick: { show: true },
        },
        series: series,
        legend: {
          top: 0,
          right: 0,
          itemWidth: 30,
          textStyle: { color: "#555" },
        },
      };
    },
  },
};
</script>

<style>
.echarts {
}
</style>