<template>
  <a-icon :component="Svg" />
</template>

<script>
import SvgJson from "./svg-icons.js";

export default {
  name: "MyIcon",
  props: ["type"],
  computed: {
    Svg() {
      return {
        template: SvgJson[this.type] || SvgJson["default"],
      };
    },
  },
};
</script>