

<template>
  <div class="comp-echart section-margin-top">
    <div class="loading-tips" v-if="isLoading"></div>
    <div class="empty-tips" v-else-if="isEmpty"></div>
    <v-chart style="width: 100%;height: 400px;" v-else :autoresize="true" :options="options" />
  </div>
</template>

<script>
import Bus from "@/utils/event-bus.js";
export default {
  data() {
    return {
      compData: undefined,
    };
  },
  mounted() {
    this.loadData();
    Bus.$on("reload-data", this.loadData);
    Bus.$on("province-change", this.loadData);
  },
  methods: {
    loadData() {
      this.$get("/screen/datav/type-orders", {
        province: this.$store.state.common.provinceName,
      }).then((r) => {
        this.compData = r.data.data || {};
      });
    },
  },
  computed: {
    isLoading() {
      return typeof this.compData == "undefined";
    },
    isEmpty() {
      let count = 0;
      for (const key in this.compData) {
        count += this.compData[key];
      }
      return !this.compData || count == 0;
    },
    taskTypes() {
      return this.$store.state.dict.dicts.task_task_type;
    },
    options() {
      let data = Object.keys(this.compData)
        .filter((key) => Boolean(this.compData[key]))
        .map((key) => {
          return {
            value: this.compData[key],
            name: this.taskTypes.find((item) => item.keyy == key).valuee,
          };
        });

      return {
        color: [
          "#37aef4",
          "#1f6691",
          "#37aef4",
          "#1f6691",
          "#37aef4",
          "#1f6691",
          "#37aef4",
          "#1f6691",
        ],
        tooltip: {
          trigger: "item",
        },
        legend: { show: false },
        series: [
          {
            name: "工单类型",
            type: "pie",
            radius: ["50%", "75%"],
            avoidLabelOverlap: false,
            label: {
              show: true,
            },
            emphasis: {
              label: {
                show: true,
                fontSize: "20",
              },

              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
            labelLine: {
              show: true,
            },
            data: data,
          },
        ],
      };
    },
  },
};
</script>
