export default {
    namespaced: true,
    state: {
      provinceName: ''
    },
    mutations: {
      setProvinceName (state, val) {
        state.provinceName = val
      },
    },
  }
  