export function triggerWindowResizeEvent () {
  let event = document.createEvent('HTMLEvents')
  event.initEvent('resize', true, true)
  event.eventType = 'message'
  window.dispatchEvent(event)
}

export function platFn(list){
  let res = []
  res = list.concat(...list.map(item => {
    if (item.children instanceof Array && item.children.length > 0) {
      return platFn(item.children)
    }
    return null
  }).filter(o => o instanceof Array && o.length > 0))
  return res
}

export function uniqueByKey(arr,key) {
  let hash = {};
  let result = arr.reduce((total, currentValue) => {
      if (!hash[currentValue[key]]) { //如果当前元素的key值没有在hash对象里，则可放入最终结果数组
        hash[currentValue[key]]= true; //把当前元素key值添加到hash对象
        total.push(currentValue); //把当前元素放入结果数组
      }
      return total; //返回结果数组
    },[]);
  return result;
}


