import db from 'utils/localstorage'

export default {
  namespaced: true,
  state: {
    token: db.get('WEB_USER_TOKEN'),
    expireTime: db.get('WEB_EXPIRE_TIME'),
    user: db.get('WEB_USER'),
    permissions: db.get('WEB_PERMISSIONS'),
    roles: db.get('WEB_ROLES')
  },
  mutations: {
    setToken (state, val) {
      db.save('WEB_USER_TOKEN', val)
      state.token = val
    },
    setExpireTime (state, val) {
      db.save('WEB_EXPIRE_TIME', val)
      state.expireTime = val
    },
    setUser (state, val) {
      db.save('WEB_USER', val)
      state.user = val
    },
    setPermissions (state, val) {
      db.save('WEB_PERMISSIONS', val)
      state.permissions = val
    },
    setRoles (state, val) {
      db.save('WEB_ROLES', val)
      state.roles = val
    }
  }
}
